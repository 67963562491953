import { Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import i18nZipcodes from 'i18n-zipcodes'

import { Config } from '../../../config'
import { SessionService } from '../../services/session.service'

import { MessageModal } from 'src/app/elements/message-modal/message.modal'
import { OtpModal } from 'src/app/elements/otp-modal/otp.modal'

import { TokenResponse, User } from '../../models/user.model'
import { Address } from '../../models/address.model'
import { Util } from '../../models/util.model'
import { Country, CountryOBJ } from '../../models/countries.models'
import { checkVAT, Country as JsvatCountry, countries as jsvatCountryList } from 'jsvat'
import { CdTimerComponent } from 'angular-cd-timer'
import { NgOtpInputComponent } from 'ng-otp-input'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

import { stateType } from 'src/app/elements/message-modal/message.modal'
import { Router } from '@angular/router'
import { NgModel } from '@angular/forms'

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
const PNF = require('google-libphonenumber').PhoneNumberFormat

type VatCheckerResponseT = {
	address: { streetAddress: string; zip: string; city: string; province: string }
	error: boolean
	fault: string | null
	organization: string
	valid: boolean
}

@Component({
	selector: 'createUser',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss'],
})
export class CreateUserComponent implements OnInit {
	@ViewChild('searchCountry') searchCountry: any
	@ViewChildren('ngOtpInput') ngOtpInput: QueryList<NgOtpInputComponent>
	@ViewChildren('timer') timer: QueryList<CdTimerComponent>

	draftUser: User

	currentModal

	countries: Country[]
	countryIso2: string
	countryDialCode: string
	vat: string

	otpPhone: string
	formattedOtpPhone: string
	jsvatCountry: JsvatCountry
	otpEmail: string
	emailError: boolean
	zipCodeError: boolean
	vatError: boolean
	internalVatCheckerError: boolean
	isFetchingUserDataFromVat: boolean
	otpServiceLoading: boolean

	disableVat: boolean

	otpPhoneError: boolean
	otpEmailError: boolean
	mainAddr: Address
	otpViaEmail: boolean
	code: string
	toggle: boolean

	constructor(
		public session: SessionService,
		public translator: TranslateService,
		private renderer: Renderer2,
		private modalService: NgbModal,
		private router: Router
	) {
		this.initNewUser()

		this.session.getCountries().then((res) => {
			this.countries = res
		})

		this.emailError = false
		this.zipCodeError = false
		this.vatError = false
		this.internalVatCheckerError = false
		this.toggle = true
		this.isFetchingUserDataFromVat = false
		this.otpPhone = ''
		this.otpEmail = ''
		this.formattedOtpPhone = ''
		this.otpServiceLoading = false

		this.otpPhoneError = false
		this.otpEmailError = false
		this.otpViaEmail = false

		// this.session.registrationType = regType.OPTICIAN // serve per il modulo opt per salvare o meno
	}

	ngOnInit(): void {}

	private initNewUser() {
		console.log('initNewUser')
		this.draftUser = new User(null)

		this.mainAddr = new Address()

		this.draftUser.role = Config.PR_OPTICIAN

		this.draftUser.user_subtype = Config.SUB_STD
		this.draftUser.is_test = 'N'

		this.disableVat = true

		// default di comodo per temporanea
		this.draftUser.password = 'password'
		this.mainAddr.country = ''
	}

	onSubmit(isFormInvalid: boolean) {
		// console.log(this.formattedOtpPhone)

		if (!isFormInvalid) {
			this.otpServiceLoading = true
			const param = this.otpViaEmail
				? { action: 'selfreg', lang: this.mainAddr.country, phoneNumber: '', isEmail: 'Y', email: this.otpEmail }
				: { action: 'selfreg', lang: this.mainAddr.country, phoneNumber: this.formattedOtpPhone, isEmail: 'N', email: '' }
			this.session
				.otpInit(param)
				.then((res) => {
					// this.otpMessageID = res.messageID

					this.currentModal = this.modalService.open(OtpModal, { size: 'l', keyboard: false, backdrop: 'static' })
					this.currentModal.componentInstance.country = this.mainAddr.country
					this.currentModal.componentInstance.formattedOtpPhone = this.formattedOtpPhone
					this.currentModal.componentInstance.otpViaEmail = this.otpViaEmail
					this.currentModal.componentInstance.otpEmail = this.otpViaEmail ? this.otpEmail : ''
					this.currentModal.componentInstance.otpMessageID = res.messageID
					this.currentModal.componentInstance.otpExpiringInSec = res.expiringInSec
					this.currentModal.componentInstance.isOptician = true

					this.currentModal.result.then((response: TokenResponse) => {
						// console.log(response)
						if (response) {
							this.session
								.savenewuser({ ...this.draftUser, vat: this.draftUser.vat ? this.draftUser.vat.replace(/ /g, '') : '' }, this.mainAddr, response)
								.then((resp) => {
									let text = [this.translator.instant('MODAL.MODAL_TRUE_BODY'), this.translator.instant('MODAL.MODAL_TRUE_BODY_SECOND-PART')]
									this.openMessage(text, stateType.SUCCESS)
								})
								.catch((err) => {
									let text = [this.translator.instant('MODAL.INTERNAL_ERROR')]
									this.openMessage(text, stateType.FAIL)
								})
						}
					})
				})
				.catch(() => {
					// this.otpInternalError = true

					let text = [this.translator.instant('MODAL.INTERNAL_ERROR')]
					this.openMessage(text, stateType.FAIL)
				})
				.finally(() => {
					this.otpServiceLoading = false
				})
		}
	}

	private openMessage(text: string[], state: stateType) {
		this.currentModal = this.modalService.open(MessageModal, { size: 'l', keyboard: false, backdrop: 'static' })
		this.currentModal.componentInstance.state = state
		this.currentModal.componentInstance.texts = text

		this.currentModal.result.then(
			(confirmed) => {
				this.session.redirect()
			},
			(reason) => {
				this.router.navigate([''])
			}
		)
	}

	// newUser(formValid) {
	// 	if (formValid == false) {
	// 		if (this.draftUser.bot == this.code) {
	// 			this.session.savenewuser(this.draftUser, this.mainAddr)
	// 		} else {
	// 			// bot not validated
	// 			const input = document.getElementById('bot') as HTMLInputElement | null
	// 			input.value = ''
	// 			input.classList.add('ng-invalid')
	// 			input.classList.remove('ng-valid')
	// 			// error message
	// 			const errorm = document.querySelector('#boterr') as HTMLInputElement | null
	// 			errorm.removeAttribute('hidden')
	// 		}
	// 	} else {
	// 		// form invalidated
	// 	}
	// }

	erase() {
		this.searchCountry.drpcountry = ''
		this.searchCountry.countrySelected = false
	}

	resetCountry(event) {
		//resetto la contry se viene cancellata nel serachDropdown
		// console.log(event);
		if (event) {
			this.mainAddr.country = ''
			this.countryDialCode = ''
			this.disableVat = true
			this.draftUser.vat = ''
		}
	}

	onVatChange(event: Event) {
		// console.log(this.vat)
		this.vat = (event.target as HTMLInputElement).value
		this.validateVat((event.target as HTMLInputElement).value)
	}

	onOtpPhoneChange(event: Event) {
		this.otpPhone = (event.target as HTMLInputElement).value
		this.validateOtpPhone((event.target as HTMLInputElement).value)
	}

	alert(cond: boolean, elem: ElementRef) {
		if (cond) {
			this.renderer.addClass(elem, 'headshake')
			setTimeout(() => {
				this.renderer.removeClass(elem, 'headshake')
			}, 1000)
		}
	}

	selCountry(selCountry: CountryOBJ) {
		console.log('(CreateUser) select country: ' + selCountry.countryIso2)
		this.countryIso2 = selCountry.countryIso2
		this.countryDialCode = selCountry.dialCode
		this.mainAddr.country = selCountry.countryIso3
		// save jsvat country to check vat (obj.codes[1] holds the iso3 code, that we check against the selected country iso3 code)
		this.jsvatCountry = jsvatCountryList.find((jsvatCountry) => {
			return jsvatCountry.codes[1] === selCountry.countryIso3
		})
		this.checkZipCode(this.mainAddr.zip)
		this.validateVat(this.vat)
		this.validateOtpPhone(this.otpPhone)
		this.toggle = false

		if (selCountry.ecommerceFlag === 'Y' && this.session.isBrand(Config.BR_DEFAULT)) {
			this.disableVat = false
			this.draftUser.vat = ''
		}

		this.otpViaEmail = selCountry.otpViaEmail === 'Y'
	}

	ErrMsgEmail(al) {
		const isOtp = al.target.attributes.id.nodeValue === 'otp_email'
		var mailformat = /[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}/ // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		if (al.target.value.match(mailformat)) {
			if (!isOtp) {
				return (this.emailError = false)
			}
			return (this.otpEmailError = false)
		} else {
			if (!isOtp) {
				return (this.emailError = true)
			}
			return (this.otpEmailError = true)
		}
	}

	// TODO: 19292-zipcode-validation refactor validation logic to properly implement custom validation
	checkZipCode(zipCode: string) {
		if (this.mainAddr.country && zipCode !== '') {
			return (this.zipCodeError = !i18nZipcodes(this.countryIso2, zipCode))
		}
	}

	checkControlForDashes(control: NgModel) {
		if (control.control.value && control.control.value.match(/^-+$/)) control.control.setErrors({ error: 'Invalid field' })
		else control.control.setErrors(null)
	}

	async validateVat(vat: string) {
		if (vat === '' || vat === undefined || !this.mainAddr.country) {
			this.vatError = false
			this.internalVatCheckerError = false
			return
		}

		this.vatError = !checkVAT(`${this.countryIso2.toUpperCase()}${vat}`, [this.jsvatCountry]).isValid

		if (!this.vatError) {
			this.isFetchingUserDataFromVat = true

			this.session
				.vatChecker({ state: this.countryIso2.toUpperCase(), vatNumber: this.vat.replace(/ /g, '') })
				.then((res: VatCheckerResponseT) => {
					if (res.valid) {
						// check for dashes only in organization (in case vat is valid, but no info is retrieved from VIES)
						if (!res.organization.match(/^-+$/)) {
							this.mainAddr.address_line1 = res.address.streetAddress
							this.mainAddr.city = res.address.city
							this.mainAddr.province = res.address.province
							this.mainAddr.zip = res.address.zip
							this.mainAddr.organization = res.organization
						}
					} else {
						if (res.error) {
							return (this.internalVatCheckerError = true)
						}

						return (this.vatError = true)
					}
				})
				.catch(() => {
					console.log('catch')
					this.internalVatCheckerError = true
				})
				.finally(() => {
					console.log('finally')
					this.isFetchingUserDataFromVat = false
				})
		}
	}

	validateOtpPhone(phoneNumber: string) {
		// console.log(phoneNumber)
		if (phoneNumber === '' || phoneNumber === undefined || !this.mainAddr.country) {
			return (this.otpPhoneError = false)
		}

		const number = phoneUtil.parseAndKeepRawInput(phoneNumber, this.countryIso2)

		this.otpPhoneError = !phoneUtil.isPossibleNumber(number)

		if (!this.otpPhoneError) {
			this.formattedOtpPhone = phoneUtil.format(number, PNF.E164)
		}
	}

	getTerms() {
		if (!this.toggle) {
			if (this.session.isBrand(Config.BR_DEFAULT)) {
				window.open(`${Config.nexusLanding as string}/en-gb/opticiantc-wo-th-07112023`, '_blank')
			} else if (this.session.isBrand(Config.BR_RDS)) {
				window.open(`${Config.nexusLanding as string}/en-gb/rds-opticiantc-wo-th-07112023`, '_blank')
			} else {
				window.open(`${Config.nexusLanding as string}`, '_blank')
			}
		}
	}

	// test(){
	// 	// let text = [this.translator.instant("CREATE_USER.MODAL_TRUE_BODY"), this.translator.instant("CREATE_USER.MODAL_TRUE_BODY_SECOND-PART")]
	// 	// this.openMessage(text, stateType.SUCCESS )

	// 	this.currentModal = this.modalService.open(OtpModal, { size: 'l', keyboard: false, backdrop: 'static' })
	// 	this.currentModal.componentInstance.mainAddr = this.mainAddr
	// 	this.currentModal.componentInstance.draftUser = this.draftUser
	// 	this.currentModal.componentInstance.formattedOtpPhone = this.formattedOtpPhone
	// 	// this.currentModal.componentInstance.otpMessageID = res.messageID

	// }
}
